
import { Component, Vue } from 'vue-property-decorator';
import FileUpload from '../components/FileUpload.vue';

@Component({
  components: { FileUpload }
})
export default class ParentComponent extends Vue {
  // fileList: any[];  // 用于存储上传的文件列表


  fileList: any[]=[{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]

  // 父组件接收子组件传递过来的 fileList 更新
  updateFileList(updatedFileList: any[]) {
    // this.fileList = updatedFileList;
    this.fileList = [...updatedFileList.map(item=>{
      if(typeof item.response === 'object' && item.response!==null){
        return {"name": item.response.data.name, "url": `${process.env.VUE_APP_API_URL}/`+item.response.data.name}
      }else { 
        return {"name": item.name, "url": item.url};
      }})]
  }

  removeFile(file: any) {
    // console.log("removeFile: " + file.name);
    this.fileList = this.fileList.filter(item => item.name !== file.name);
    // this.fileQueue = this.fileQueue.filter(item => item.name !== file.name);
    }

  checkFile() {
    console.log(this.fileList);
    // console.log(this.fileQueue);
  }

}


